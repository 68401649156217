import { Avatar, MenuList, Popover, Tooltip } from "@mui/material";
import { FC, MouseEventHandler, useState, useMemo } from "react";
import { useStyles } from "./styles";
import { useAppSelector } from "store/hooks/useAppSelector";
import { useLocation } from "react-router-dom";
import Version from "./Version/Version";
import SignOut from "./SignOut/SignOut";
import { useTheme } from "@mui/styles";
import config from "config";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useAuthentication from "hooks/useAuthentication";
import { clientIdParam } from "utils/queryParams";
import CustomTypography from "components/CustomTypography/CustomTypography";
import ClientsPicker from "components/ClientsPicker/ClientsPicker";
import { getAccountForClient } from "selectors/auth";
import { getActiveCoreClients } from "selectors/core";

type Props = {
    collapsed: boolean;
};

export const MENU_ITEM_HEIGHT = 48;

const UserMenu: FC<Props> = ({ collapsed }) => {
    const classes = useStyles();
    const theme: any = useTheme();

    const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLDivElement) | null>(null);
    const [isAccountClicked, setisAccountClicked] = useState(false);
    const open = Boolean(anchorEl);

    const { getUserEmail } = useAuthentication();
    const coreClients = useAppSelector(getActiveCoreClients);

    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const clientId = query.get(clientIdParam);

    const currentUserAccount = useAppSelector(getAccountForClient(clientId));

    const activeCoreClient = coreClients.find((x) => x.id === clientId);

    const id = open ? "user-menu" : undefined;

    const onAccountclick: MouseEventHandler<HTMLDivElement> = (e) => {
        setAnchorEl(e.currentTarget);
        setisAccountClicked(!isAccountClicked);
    };

    const handleCloseAccountMenu = () => {
        setAnchorEl(null);
        setisAccountClicked(!isAccountClicked);
    };

    const initialsAvatar = () => {
        if (currentUserAccount) {
            return (currentUserAccount.firstName[0] + currentUserAccount.lastName[0]).toUpperCase();
        } else {
            const email = getUserEmail();
            if (email && email.length >= 2) return (email[0] + email[1]).toUpperCase();
            return "User";
        }
    };

    return (
        <>
            <Tooltip title="Account" placement="bottom">
                <div className={classes.accountButton} onClick={onAccountclick}>
                    <Avatar className={classes.smallAvatar}>{initialsAvatar()}</Avatar>
                    {!collapsed && (
                        <>
                            <div className={classes.smallProfileInfo}>
                                <CustomTypography className={classes.overflowHidden} variant="body2">
                                    {!!currentUserAccount ? `${currentUserAccount.firstName} ${currentUserAccount.lastName}` : getUserEmail()}
                                </CustomTypography>
                                <CustomTypography variant="caption" className={classes.overflowHidden} color={theme.customColors.mutedColor}>
                                    {activeCoreClient?.name}
                                </CustomTypography>
                            </div>
                            <ArrowDropDownIcon fontSize="small" />
                        </>
                    )}
                </div>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseAccountMenu}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                keepMounted
            >
                <MenuList id="user-menu-aria" sx={{ padding: 0 }}>
                    <div className={classes.largeProfileInfo}>
                        <Avatar className={classes.largeAvatar}>{initialsAvatar()}</Avatar>

                        {currentUserAccount && <CustomTypography variant="body2">{`${currentUserAccount.firstName} ${currentUserAccount.lastName}`}</CustomTypography>}
                        <CustomTypography variant="caption" color={theme.customColors.mutedColor}>
                            {getUserEmail()}
                        </CustomTypography>

                        <a href={config.coreAppUrl + "my-profile/account"} target="_blank" className={classes.myAccount}>
                            My Account
                        </a>
                    </div>

                    {!!clientId && <ClientsPicker coreClients={coreClients} clientId={clientId} />}

                    <Version />

                    <SignOut onSignOut={() => setAnchorEl(null)} />
                </MenuList>
            </Popover>
        </>
    );
};

export default UserMenu;
