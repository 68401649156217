export default {
    coreApiUrl: "https://core-api.lautec.com/v1/",
    coreAppUrl: "https://lauteccorewebtest.z19.web.core.windows.net/",
    moduleId: "979.PkNiNyg-",
    urls: {
        westeurope: { viewer: "https://viewer.eu.windgis.lautec.com", admin: "https://admin.eu.windgis.lautec.com" },
        australiaeast: { viewer: "https://viewer.aus.windgis.lautec.com", admin: "https://admin.aus.windgis.lautec.com" },
        eastus: { viewer: "https://viewer.us.windgis.lautec.com", admin: "https://admin.us.windgis.lautec.com" },
        koreasouth: { viewer: "https://viewer.asia.windgis.lautec.com", admin: "https://admin.asia.windgis.lautec.com" }
    },
    apiUrls: {
        westeurope: "https://api.eu.windgis.lautec.com/api/landing",
        australiaeast: "https://api.aus.windgis.lautec.com/api/landing",
        eastus: "https://api.us.windgis.lautec.com/api/landing",
        koreasouth: "https://api.asia.windgis.lautec.com/api/landing"
    },
    azureB2C: {
        auth: {
            clientId: "2293eaaf-1943-41b8-95b6-6994c87a9dab", // This is the ONLY mandatory field that you need to supply.
            authority: "https://identity.lautec.com/lautecb2cprod.onmicrosoft.com/b2c_1a_signin", // Defaults to "https://login.microsoftonline.com/common"
            passwordResetAuthority: "https://identity.lautec.com/lautecb2cprod.onmicrosoft.com/b2c_1a_passwordreset",
            knownAuthorities: ["identity.lautec.com"], // Mark your B2C tenant's domain as trusted.
            redirectUri: "/", // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
            postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
            navigateToLoginRequestUrl: false // If "true", will navigate back to the original request location before processing the auth code response.
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true
        },
        system: {
            allowRedirectInIframe: true
        }
    },
    loginRequest: {
        scopes: ["https://lautecb2cprod.onmicrosoft.com/apis/default"]
    },
    forgetPasswordErrorCode: "AADB2C90118",
    coreModule: {
        name: "Core",
        description: "Account Management",
        status: 1,
        locationUrl: "https://core.lautec.com/",
        favIconUrl: "https://lautecprod.blob.core.windows.net/externals/favicon-core-module.svg",
        landingPageUrl: "https://core.lautec.com/"
    },
    analyticsModuleId: "iUko.wY.DRI-"
};
