import CustomTypography from "components/CustomTypography/CustomTypography";
import { CoreProject } from "models/core/CoreProject";
import { FC } from "react";
import { useStyles } from "./styles";
import { CoreClient } from "models/core/CoreClient";
import Project from "../Project/Project";
import { StatusType } from "models/core/StatusType";
import ProjectSkeleton from "../Project/ProjectSkeleton";
import { useAppSelector } from "store/hooks/useAppSelector";
import { getCoreClientsAndProjectsFetching } from "selectors/core";
import { Grow } from "@mui/material";

type Props = {
    client: CoreClient | undefined;
    projectSearchQuery: string;
};

const ClientGrid: FC<Props> = ({ client, projectSearchQuery }) => {
    const classes = useStyles();
    const clientsLoading = useAppSelector(getCoreClientsAndProjectsFetching);

    const renderProjects = (projects: CoreProject[]) =>
        projects
            .filter((x) => x.status === StatusType.Active)
            .filter((x) => x.name.toLowerCase().includes(projectSearchQuery.toLowerCase()))
            .map((project) => (
                <Grow key={project.id}>
                    <Project project={project} />
                </Grow>
            ));

    const renderSkeleton = () =>
        Array(5)
            .fill(undefined)
            .map((_, idx) => (
                <Grow key={idx} style={{ transitionDelay: "1000ms" }}>
                    <ProjectSkeleton />
                </Grow>
            ));

    const projects = renderProjects(client?.projects || []);

    return (
        <>
            <div className={classes.clientGrid}>
                {renderProjects(client?.projects || [])}
                {clientsLoading && renderSkeleton()}
            </div>
            {projects.length === 0 && !clientsLoading && (
                <div className={classes.noProjects}>
                    <CustomTypography>No projects</CustomTypography>
                </div>
            )}
        </>
    );
};

export default ClientGrid;
