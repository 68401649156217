import { CircularProgress, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import { FC } from "react";
import CustomTypography from "../CustomTypography/CustomTypography";

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: "rgb(245, 247, 248)",
        height: "100vh",
        width: "100%"
    }
}));

type Props = {
    text: string;
};

const LoaderComponent: FC<Props> = ({ text }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.root} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <Grid container flexDirection={"column"} justifyContent="center" alignItems="center">
                    <CircularProgress size={60} />
                    <CustomTypography variant="h6">{text}</CustomTypography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LoaderComponent;
