import { CoreClient } from "models/core/CoreClient";
import { RootState } from "../store/models/RootState";
import { createParameterizedSelector, forwardArg } from "./common";
import { StatusType } from "models/core/StatusType";

export const getActiveCoreClients = (state: RootState) => state.core.coreClients.filter((x) => x.status === StatusType.Active);
export const getCoreClientsAndProjectsFetching = (state: RootState) => state.core.fetchingClientsAndProjects;

export const getRolesFetching = (state: RootState) => state.core.fetchingRoles;

export const getClient = createParameterizedSelector([getActiveCoreClients, forwardArg], (clients: CoreClient[], clientId: string) =>
    clients.find((client) => client.id === clientId)
);
export const getModuleId = (state: RootState) => state.core.moduleId;
export const getModules = (state: RootState) => state.core.modules;

export const getCoreAccountPermissions = (state: RootState) => state.core.accountPermissions;

export const getCoreAccountPermissionsFetching = (state: RootState) => state.core.fetchingAccountPermissions;