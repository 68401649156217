export enum ModuleStatus {
    Active = 1,
    Inactive
}

export type Module = {
    id: string;
    name: string;
    description: string;
    locationUrl: string;
    favIconUrl: string;
    status: ModuleStatus;
    iconUrl?: string;
    abbreviation?: string;
    landingPageUrl: string;
};
