import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useStyles } from "./styles";
import ModuleItem from "../ModuleItem/ModuleItem";
import AppsIcon from "@mui/icons-material/Apps";
import { useAppSelector } from "store/hooks/useAppSelector";
import { getClient, getCoreAccountPermissions, getCoreAccountPermissionsFetching, getModules } from "selectors/core";
import CustomTypography from "components/CustomTypography/CustomTypography";
import { useLocation } from "react-router-dom";
import { clientIdParam } from "../../utils/queryParams";
import { getAccountForClient } from "../../selectors/auth";
import LoadingPlaceholder from "../LoadingPlaceholder/LoadingPlaceholder";
import { useAppDispatch } from "../../store/hooks/useAppDispatch";
import { fetchAccountPermissions } from "../../actions/core";
import { CoreRole } from "../../models/core/CoreRole";
import config from "../../config";
import { ModuleStatus } from "../../models/core/CoreModule";

const buildRedirectUrl = (locationUrl: string, clientId: string) => {
    try {
        const url = new URL(locationUrl);
        url.searchParams.append("clientId", clientId);

        return url.href;
    } catch (error) {
        return "#";
    }
};

const ModulePicker = () => {
    const { headerIconButton, icon } = useStyles();
    const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLElement) | null>(null);
    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const clientId = query.get(clientIdParam) ?? "";

    const coreModule = config.coreModule;
    const analyticsModuleId = config.analyticsModuleId;

    const accountPermissionsFetching = useAppSelector(getCoreAccountPermissionsFetching);
    const currentUserAccount = useAppSelector(getAccountForClient(clientId));
    const modules = useAppSelector(getModules);
    const accountPermissions = useAppSelector(getCoreAccountPermissions);
    const client = useAppSelector(getClient(clientId));

    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!!clientId)
            dispatch(fetchAccountPermissions(clientId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId]);

    const isCurrentUserClientOrSystemAdmin = (currentUserAccount?.accountType === CoreRole.SystemAdmin)
        || (currentUserAccount?.accountType === CoreRole.ClientAdmin);

    const isModuleEnabledForClient = (moduleId: string) => client?.modules?.some(m => m === moduleId)
        ?? false;

    const hasModuleAccess = (moduleId: string) => isCurrentUserClientOrSystemAdmin ||
        (moduleId === analyticsModuleId
            ? !!accountPermissions.accountReportPermissions.length
            : accountPermissions.accountProjectPermissions.some(
                (projectPermission) =>
                    projectPermission.project.clientId === clientId && projectPermission.modulePermissions
                        .some((modulePermission) => modulePermission.moduleId === moduleId)
            ));

    const isModuleAccessible = (moduleId: string) => isModuleEnabledForClient(moduleId) && hasModuleAccess(moduleId);

    const isModuleActive = (status: ModuleStatus) => status === ModuleStatus.Active;

    const open = !!anchorEl;

    const onClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Tooltip title="Other modules">
                <IconButton className={headerIconButton} onClick={onClick}>
                    <AppsIcon className={icon} />
                </IconButton>
            </Tooltip>

            <Menu anchorEl={anchorEl} id="account-menu" open={open} onClose={onClose} onClick={onClose}>
                <LoadingPlaceholder loading={accountPermissionsFetching}>
                    {modules.length ? (
                        [...modules.map((module, index) => {
                            const isAccessible = isModuleAccessible(module.id);

                            const url = isAccessible && isModuleActive(module.status) ?
                                buildRedirectUrl(module.locationUrl, clientId) : module.landingPageUrl;

                            return (
                                <ModuleItem
                                    key={index}
                                    description={module.description}
                                    favIconUrl={module.favIconUrl}
                                    name={module.name}
                                    moduleUrl={url}
                                    isAccessible={isAccessible}
                                />

                            );
                        }),
                            <ModuleItem
                                key={coreModule.id}
                                description={coreModule.description}
                                favIconUrl={coreModule.favIconUrl}
                                name={coreModule.name}
                                moduleUrl={buildRedirectUrl(coreModule.locationUrl, clientId)}
                                isAccessible
                            />
                        ]

                    ) : (
                        <MenuItem disabled>
                            <CustomTypography variant="h3">No modules available at the moment</CustomTypography>
                        </MenuItem>
                    )}
                </LoadingPlaceholder>
            </Menu>
        </>
    );
};

export default ModulePicker;
