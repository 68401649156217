import { createSlice } from "@reduxjs/toolkit";
import { fetchAccessibleClientsAndProjects, fetchAccountPermissions, fetchModules } from "actions/core";
import config from "config";
import { CoreClient } from "models/core/CoreClient";
import { Module } from "models/core/CoreModule";
import { CoreAccountPermissions } from "../models/core/CoreAccountPermissions";
import { fetchRolesForProjects } from "actions/atlas";
import { ProjectRole } from "models/core/ProjectRole";

type SliceState = {
    coreClients: CoreClient[];
    projectRoles: ProjectRole[];
    fetchingRoles: boolean;
    fetchingClientsAndProjects: boolean;
    fetchingModules: boolean;
    fetchingAccountPermissions: boolean;
    moduleId: string;
    modules: Module[];
    accountPermissions: CoreAccountPermissions
};

const initialState: SliceState = {
    coreClients: [],
    projectRoles: [],
    fetchingModules: false,
    fetchingClientsAndProjects: false,
    fetchingRoles: false,
    fetchingAccountPermissions: false,
    moduleId: config.moduleId,
    modules: [],
    accountPermissions: { accountProjectPermissions: [], accountReportPermissions: [] }
};

const coreInfoSlice = createSlice({
    name: "core",
    initialState,
    reducers: {
        updateProjectRoles: (state, { payload: { clientId, projectsRoles } }) => {
            const client = state.coreClients.find(client => client.id === clientId);
            if (client) {
                client.projects.forEach(project => {
                    if (projectsRoles[project.id]) {
                        project.role = projectsRoles[project.id];
                    }
                });
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchModules.fulfilled, (state, { payload: modules }) => {
                state.modules = [...modules, ...initialState.modules];
                state.fetchingModules = false;
            })
            .addCase(fetchModules.pending, (state) => {
                state.fetchingModules = true;
            })
            .addCase(fetchModules.rejected, (state) => {
                state.fetchingModules = false;
            })
            .addCase(fetchAccountPermissions.fulfilled, (state, { payload }) => {
                state.accountPermissions = payload;
                state.fetchingAccountPermissions = false;
            })
            .addCase(fetchAccountPermissions.pending, (state) => {
                state.fetchingAccountPermissions = true;
            })
            .addCase(fetchAccountPermissions.rejected, (state) => {
                state.fetchingAccountPermissions = false;
            })
            .addCase(fetchAccessibleClientsAndProjects.fulfilled, (state, { payload }) => {
                state.coreClients = payload?.items ?? [];
                state.fetchingClientsAndProjects = false;
            })
            .addCase(fetchAccessibleClientsAndProjects.pending, (state) => {
                state.fetchingClientsAndProjects = true;
            })
            .addCase(fetchAccessibleClientsAndProjects.rejected, (state) => {
                state.fetchingClientsAndProjects = false;
            })
            .addCase(fetchRolesForProjects.rejected, (state) => {
                state.fetchingRoles = false;
            })
            .addCase(fetchRolesForProjects.pending, (state) => {
                state.fetchingRoles = true;
            })
            .addCase(fetchRolesForProjects.fulfilled, (state) => {
                state.fetchingRoles = false;
            });
    }
});


export const { updateProjectRoles } = coreInfoSlice.actions;
export default coreInfoSlice.reducer;