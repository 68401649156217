import config from "../config";
import axios, { AxiosInstance } from "axios";

const baseConfiguration = {
    timeout: 300000,
    headers: {
        "Content-Type": "application/json"
    },
    withCredentials: false
};

export const atlasApiClients = Object.keys(config.apiUrls)
    .reduce<{ [region: string]: AxiosInstance }>((acc, region) => {
        acc[region] = axios.create({
            ...baseConfiguration,
            baseURL: config.apiUrls[region]
        });

        return acc;
    }, {});

export const atlasApiClientsWithRegions = Object.entries(atlasApiClients).map(([region, client]) => ({
    region,
    client
}));

export const atlasApiClientsArray = Object.values(atlasApiClients);