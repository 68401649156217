import makeStyles from "@mui/styles/makeStyles";

export const useLandingViewStyles = makeStyles((theme: any) => ({
    root: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column"
    },
    subHeader: {
        paddingInline: theme.spacing(3),
        borderBottom: `1px solid ${theme.customColors.borderColor}`,
        minHeight: 50,
        display: "flex",
        alignItems: "center"
    },
    pageTitle: {
        margin: 0,
        fontSize: 20,
        fontWeight: 400
    },
    searchHeader: {
        minHeight: 60,
        backgroundColor: theme.customColors.appBgColor,
        paddingInline: theme.spacing(3),
        display: "flex",
        alignItems: "center"
    },
    contentArea: {
        flex: "1 1 auto",
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        flexWrap: "nowrap",
        padding: theme.spacing(3),
        borderTop: `1px solid ${theme.customColors.borderColor}`
    },
    searchField: {
        width: "100%",
        maxWidth: 400
    },
    searchIcon: {
        width: 20
    }
}));
