import { createSlice, isAnyOf, isFulfilled, isRejected, PayloadAction } from "@reduxjs/toolkit";
import { User } from "models/User";
import { fetchCurrentUser } from "actions/core";

type State = {
    fetching: boolean;
    user?: User;
};

const initialState: State = {
    fetching: true,
    user: undefined
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCurrentUser.pending, (state) => {
            state.fetching = true;
        });

        builder.addCase(fetchCurrentUser.fulfilled, (state, { payload }) => {
            state.user = payload;
        });

        builder.addMatcher(isAnyOf(isFulfilled(fetchCurrentUser), isRejected(fetchCurrentUser)), (state) => {
            state.fetching = false;
        });
    }
});

export const {} = authSlice.actions;

export default authSlice.reducer;
