import ButtonLink from "components/ButtonLink/ButtonLink";
import HeaderButtons from "components/HeaderButtons/HeaderButtons";
import { FC } from "react";
import { useStyles } from "./styles";

const Header: FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.topBar}>
            <ButtonLink to="/">
                <img src="/Logo.svg" className={classes.logo} alt="Logo" />
            </ButtonLink>
            <HeaderButtons />
        </div>
    );
};

export default Header;
