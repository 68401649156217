import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flexDirection: "column",
        height: "80%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center"
    },
    bottomMargin: {
        marginBottom: 16
    }
}));
