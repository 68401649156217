import { createAsyncThunk } from "@reduxjs/toolkit";
import { atlasApiClientsWithRegions } from "./atlasApiClient";
import { updateProjectRoles } from "../reducers/core";

export type ProjectDetails = {
    [region: string]: string[]
};

type FetchRolesForProjectsRequestType = {
    clientId: string,
    projectDetails: ProjectDetails
};

export const fetchRolesForProjects = createAsyncThunk('fetchRolesFromProjects', async ({ clientId, projectDetails }: FetchRolesForProjectsRequestType, { dispatch }) => {
    const promises = atlasApiClientsWithRegions.filter(element => element.region in projectDetails).map(async (element) => {
        try {

            const params = new URLSearchParams();
            projectDetails[element.region].forEach(id => params.append('projectIds', id));

            const response = await element.client.get(`${clientId}/roles?${params.toString()}`);

            const projectsRoles = response.data;
            dispatch(updateProjectRoles({ clientId, projectsRoles }));

        } catch (error) {
            console.error(`Error fetching roles for region ${element.region}`, error);
        }
    });
    await Promise.all(promises);
});