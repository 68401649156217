import { Box } from "@mui/material";
import { useStyles } from "./styles";
import clsx from "clsx";
import UserMenu from "components/UserMenu/UserMenu";
import ModulePicker from "components/ModulePicker/ModulePicker";
import { FC } from "react";

type Props = {
    className?: string;
    userMenuIsCollapsed?: boolean;
};

const HeaderButtons: FC<Props> = ({ className = "", userMenuIsCollapsed = false }) => {
    const classes = useStyles();
    return (
        <Box className={clsx(classes.headerButtons, className)}>
            <ModulePicker />
            <UserMenu collapsed={userMenuIsCollapsed} />
        </Box>
    );
};

export default HeaderButtons;
