import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    headerIconButton: {
        width: 30,
        height: 30,
        borderRadius: 4
    },
    icon: {
        color: "black"
    }
}));
