import { CircularProgress } from "@mui/material";
import { FCWC } from "react";
import { useStyles } from "./styles";
import CustomTypography from "components/CustomTypography/CustomTypography";

type Props = {
    loading: boolean;
    message?: string;
    textVariant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button" | "overline" | "inherit" | undefined;
    spinnerSize?: number;
};

const LoadingPlaceholder: FCWC<Props> = ({ children, loading, message = "Loading", textVariant = "body1", spinnerSize = 50 }) => {
    const classes = useStyles();
    return loading ? (
        <div className={classes.container}>
            <CustomTypography className={classes.bottomMargin} variant={textVariant}>
                {message}
            </CustomTypography>
            <CircularProgress size={spinnerSize} />
        </div>
    ) : (
        <>{children}</>
    );
};

export default LoadingPlaceholder;
