import { Box, Card, Grid, Skeleton } from "@mui/material";
import CustomTypography from "components/CustomTypography/CustomTypography";
import { useStyles } from "./styles";
import React, { FC, forwardRef } from "react";

const ANIMATION: "pulse" | "wave" = "wave";

type Props = {
    ref?: React.Ref<HTMLDivElement>;
};

const ProjectSkeleton: FC<Props> = forwardRef((_, ref) => {
    const classes = useStyles();

    return (
        <Card className={classes.projectCard}>
            <Grid container height="100%" padding={2}>
                <Grid item xs={4} pr={2} display="flex" alignItems="center" justifyContent="center">
                    <Skeleton animation={ANIMATION} variant="rectangular" width="100%" height="100%" />
                </Grid>
                <Grid item xs={8} display="flex" flexDirection="column">
                    <CustomTypography className={classes.projectName} variant="h6">
                        <Skeleton animation={ANIMATION} width="100%" />
                    </CustomTypography>
                    <CustomTypography className={classes.projectAbbreviation} variant="body2">
                        <Skeleton animation={ANIMATION} width="40%" />
                    </CustomTypography>
                    <Box display="flex" gap={2} mt="auto" pt={1}>
                        <Skeleton animation={ANIMATION} className={classes.appLink} width="30%" />
                        <Skeleton animation={ANIMATION} className={classes.appLink} width="30%" />
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
});

export default ProjectSkeleton;
