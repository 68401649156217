import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: any) => ({
    clientGrid: {
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
        gap: theme.spacing(3)
    },
    clientName: {
        fontSize: 24
    },
    noProjects: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.customColors.mutedColor,
        fontWeight: "bold"
    }
}));
