import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    accountButton: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
    },
    smallProfileInfo: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        marginLeft: 8,
        marginRight: 4,
        maxWidth: 100,
        height: 30
    },
    largeProfileInfo: {
        display: "flex",
        textAlign: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: 16,
        borderBottom: `1px solid ${theme.customColors.lightGray}`
    },
    smallAvatar: {
        width: 30,
        height: 30,
        fontSize: 12,
        backgroundColor: theme.customColors.appBgColorDark
    },
    largeAvatar: {
        width: 50,
        height: 50,
        fontSize: 16,
        fontWeight: "bold",
        marginBottom: 8,
        backgroundColor: theme.customColors.appBgColorDark
    },
    username: {
        marginLeft: 8,
        display: "flex",
        alignItems: "center",
        lineHeight: 1,
        textTransform: "capitalize"
    },

    myAccount: {
        textDecoration: "none",
        color: theme.customColors.primary3,
        fontSize: 14
    },
    overflowHidden: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden"
    }
}));
