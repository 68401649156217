import {coreApiClient} from "actions/coreApiClient";
import {InternalAxiosRequestConfig} from "axios";
import {useEffect, useState} from "react";
import useAuthentication from "./useAuthentication";
import {atlasApiClientsArray} from "../actions/atlasApiClient";

export const useRequestInterceptor = () => {
    const [loading, setLoading] = useState(true);
    const { getAccessToken } = useAuthentication();

    const tokenInterceptor = async (cfg: InternalAxiosRequestConfig) => {
        const accessToken = await getAccessToken();
        cfg.headers.Authorization = `Bearer ${accessToken}`;

        return cfg;
    };

    useEffect(() => {
        const coreApiInterceptorId = coreApiClient.interceptors.request.use(tokenInterceptor);

        const atlasApiInterceptorIds = atlasApiClientsArray
            .map(client => client.interceptors.request.use(tokenInterceptor));

        setLoading(false);

        return () => {
            coreApiClient.interceptors.request.eject(coreApiInterceptorId);

            atlasApiClientsArray
                .forEach((client, idx) => client.interceptors.request.eject(atlasApiInterceptorIds[idx]));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loading;
};
