import authReducer from "reducers/auth";
import coreReducer from "reducers/core";
import { reducer as toastrReducer } from "react-redux-toastr";

import { combineReducers } from "redux";

// main reducers
export const rootReducer = combineReducers({
    auth: authReducer,
    core: coreReducer,
    toastr: toastrReducer // <- Mounted at toastr.
});
