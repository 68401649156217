import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: any) => ({
    projectCard: {
        color: theme.customColors.appTextColor,
        background: theme.customColors.appBgColorLight,
        boxShadow: "0 2px 12px 1px rgba(60,62,66,0.1)"
    },
    projectLogo: {
        maxWidth: "100%",
        maxHeight: 64
    },
    projectName: {
        display: "flex",
        justifyContent: "space-between",
        gap: 8,
        fontSize: 16,
        fontWeight: 700,
        lineHeight: 1.3
    },
    projectAbbreviation: {
        color: theme.customColors.mutedColor
    },
    appLink: {
        textTransform: "capitalize",
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(0.5),
        fontSize: 13,
        fontWeight: 500
    },
    linksWrapper: {
        '&:empty': {
            display: "none"
        }
    }
}));
