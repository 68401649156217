import React, { ComponentProps, ForwardedRef } from "react";
import { Typography } from "@mui/material";

type Props = ComponentProps<typeof Typography> & {
    textWeight?: string;
};

const CustomTypography = (props: Props, ref: ForwardedRef<HTMLSpanElement>) => {
    const { textWeight, className, ...otherProps } = props;

    let weight;
    switch (textWeight) {
        case "regular":
            weight = "400";
            break;
        case "semibold":
            weight = "600";
            break;
        case "bold":
            weight = "700";
            break;
        default:
            weight = "inherit";
    }

    return (
        <Typography ref={ref} fontWeight={weight} className={` ${className ? className : ""}`} {...otherProps}>
            {props.children}
        </Typography>
    );
};

export default React.forwardRef(CustomTypography);
