import { rootReducer } from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";

type WindowWithReduxDev = typeof window & { __REDUX_DEVTOOLS_EXTENSION__: boolean };

// As an alternative to using the default middlewares, we need to enforce a tuple constraint on the middleware array
// and change the type of the thunk middlewares
// Ex: middleware: [thunk as ThunkMiddleware<RootState, AnyAction>, authMiddleware, signalRMiddleware] as const,
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: true, immutableCheck: false, serializableCheck: false }),
    devTools: process.env.NODE_ENV !== "production" && (window as WindowWithReduxDev).__REDUX_DEVTOOLS_EXTENSION__
});
