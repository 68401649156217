import { createAsyncThunk } from "@reduxjs/toolkit";
import { User } from "models/User";
import { Module } from "models/core/CoreModule";
import { coreApiClient } from "./coreApiClient";
import { CoreAccountPermissions } from "../models/core/CoreAccountPermissions";
import config from "config";
import { PaginatedCollection } from "models/core/AccessibleClientsAndProjects";

export const fetchCurrentUser = createAsyncThunk("fetchCurrentUser", () => coreApiClient.get<User>("user").then((res) => res.data));

export const fetchModules = createAsyncThunk("fetchModules", () => {
    return coreApiClient.get<Module[]>("module/list").then((res) => res.data);
});

export const fetchAccountPermissions = createAsyncThunk("fetchAccountPermissions", (clientId: string) =>
    coreApiClient.get<CoreAccountPermissions>("account/myPermissions", { headers: { "ClientId": clientId } })
        .then((res) => res.data));

export const fetchAccessibleClientsAndProjects = createAsyncThunk("fetchAccessibleClientsAndProjects", () =>
    coreApiClient.get<PaginatedCollection>(`client/listAccessible/${config.moduleId}`)
        .then((res) => res.data)
        .catch((error) => console.error("List accessible error: ", error)));
