import { MsalAuthenticationResult } from "@azure/msal-react";
import CustomTypography from "components/CustomTypography/CustomTypography";

export const MsalErrorComponent: React.FC<MsalAuthenticationResult> = ({ error }) => {
    return (
        <CustomTypography textAlign="center">
            <>Oops... {error}</>
        </CustomTypography>
    );
};
