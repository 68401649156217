import { Box, Card, Grid, Link, Skeleton, Tooltip } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import noLogoPlaceholder from "./NoLogoPlaceholder.svg";
import CustomTypography from "components/CustomTypography/CustomTypography";
import config from "config";
import { Urls } from "models/Urls";
import { CoreProject } from "models/core/CoreProject";
import { FC, forwardRef } from "react";
import { useStyles } from "./styles";
import WarningIcon from "@mui/icons-material/Warning";

import { GisRole } from "../../../models/GisRole";
import { useAppSelector } from "store/hooks/useAppSelector";
import { getRolesFetching } from "selectors/core";

type Props = {
    project: CoreProject;
};

const ANIMATION: "pulse" | "wave" = "wave";

const Project: FC<Props & { ref?: React.Ref<HTMLDivElement> }> = forwardRef(({ project }, _) => {
    const classes = useStyles();
    const urls: Urls = config.urls;

    const rolesLoading = useAppSelector(getRolesFetching);

    const getPathname = (project: CoreProject, app: string) => {
        if (!project.region) return "/";

        const url = urls[project.region.id][app];
        if (!url) return "/";

        return `${url}/${project.clientId}/${project.id}/`;
    };

    const renderAppLinks = (project: CoreProject) => {
        if (!project.region) return;

        let apps = { ...urls[project.region.id] };
        if (!apps) return;

        if (rolesLoading && !project.role) {
            return (
                <Box width="100%" display="flex" gap={2} mt="auto" pt={1}>
                    <Skeleton animation={ANIMATION} className={classes.appLink} width="30%" />
                    <Skeleton animation={ANIMATION} className={classes.appLink} width="30%" />
                </Box>
            );
        }

        if (!project.role) return;

        if (project.role === GisRole.User) {
            delete apps["admin"];
        }

        return Object.keys(apps).map((app) => (
            <Link href={getPathname(project, app)} key={app} underline="hover" className={classes.appLink}>
                <>
                    {app} <OpenInNewIcon fontSize="small" />
                </>
            </Link>
        ));
    };

    const renderMessage = () => {
        const error = (
            <Tooltip title="WindGIS has not been setup yet for this project">
                <WarningIcon fontSize="inherit" color="warning" />
            </Tooltip>
        );
        if (!project.region) return error;

        const apps = urls[project.region.id];
        if (!apps) return error;
    };

    return (
        <Card className={classes.projectCard}>
            <Grid container height="100%" padding={2}>
                <Grid item xs={4} pr={2} display="flex" alignItems="center" justifyContent="center">
                    <img src={!!project.logoUrl ? project.logoUrl : noLogoPlaceholder} className={classes.projectLogo} alt="Project Logo" />
                </Grid>
                <Grid item xs={8} display="flex" flexDirection="column" justifyContent="center">
                    <>
                        <CustomTypography className={classes.projectName} variant="h6">
                            {project.name}
                            {renderMessage()}
                        </CustomTypography>
                        {!!project.abbreviation && (
                            <CustomTypography className={classes.projectAbbreviation} variant="body2">
                                ({project.abbreviation})
                            </CustomTypography>
                        )}
                        <Box className={classes.linksWrapper} display="flex" gap={2} mt="auto" pt={1}>
                            {renderAppLinks(project)}
                        </Box>
                    </>
                </Grid>
            </Grid>
        </Card>
    );
});

export default Project;
