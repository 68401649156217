import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: any) => ({
    topBar: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(0.25, 3),
        minHeight: 50,
        background: theme.customColors.appBgColorLight,
        borderBottom: `1px solid ${theme.customColors.borderColor}`,
        position: "sticky",
        top: 0,
        zIndex: theme.zIndex.appBar
    },
    logo: {
        height: 30
    }
}));
