import { InputAdornment, TextField } from "@mui/material";
import ErrorPlaceholder from "components/ErrorPlaceholder/ErrorPlaceholder";
import { FC, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getActiveCoreClients, getCoreClientsAndProjectsFetching } from "selectors/core";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { useAppSelector } from "store/hooks/useAppSelector";
import { clientIdParam } from "utils/queryParams";
import ClientGrid from "./Client/ClientGrid";
import Header from "./Header/Header";
import SearchIcon from "@mui/icons-material/Search";
import { useLandingViewStyles } from "./styles";
import { fetchRolesForProjects, ProjectDetails } from "actions/atlas";
import { fetchAccessibleClientsAndProjects } from "actions/core";

const CORE_CLIENT_KEY = "LATEST_CORE_CLIENT";

const LandingView: FC = () => {
    const classes = useLandingViewStyles();

    const [searchQuery, setSearchQuery] = useState("");

    const coreClients = useAppSelector(getActiveCoreClients);
    const clientsLoading = useAppSelector(getCoreClientsAndProjectsFetching);

    const dispatch = useAppDispatch();

    const history = useHistory();

    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const clientId = query.get(clientIdParam);

    useEffect(() => {
        if (!clientId) {
            const savedId = localStorage.getItem(CORE_CLIENT_KEY) ?? coreClients?.[0]?.id;
            if (!!savedId) history.push("?clientId=" + savedId);
        } else {
            localStorage.setItem(CORE_CLIENT_KEY, clientId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId, coreClients]);

    const selectedClient = useMemo(() => {
        return !clientId ? coreClients[0] : coreClients.find((x) => x.id === clientId);
    }, [clientId, coreClients]);

    useEffect(() => {
        if (selectedClient) {
            const rolesForSelectedClient = selectedClient.projects.filter((proj) => proj.role);

            rolesForSelectedClient.length === 0 &&
                dispatch(
                    fetchRolesForProjects({
                        clientId: selectedClient.id,
                        projectDetails: selectedClient.projects.reduce((acc: ProjectDetails, el) => {
                            if (!el.region) return acc;
                            if (el.region.id in acc) {
                                acc[el.region.id].push(el.id);
                            } else {
                                acc[el.region.id] = [el.id];
                            }
                            return acc;
                        }, {})
                    })
                );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedClient]);

    return (
        <div className={classes.root}>
            <Header />
            <div className={classes.subHeader}>
                <h2 className={classes.pageTitle}>Projects</h2>
            </div>

            <ErrorPlaceholder
                error={!clientsLoading && coreClients.length === 0}
                message="You don't have permission to any client"
                textVariant="h5"
                onTryAgain={() => fetchAccessibleClientsAndProjects()}
            >
                <>
                    <div className={classes.searchHeader}>
                        <TextField
                            value={searchQuery}
                            onChange={(e) => {
                                setSearchQuery(e.target.value);
                            }}
                            placeholder="Search for Project"
                            hiddenLabel
                            className={classes.searchField}
                            variant="filled"
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon className={classes.searchIcon} />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </div>
                    <div className={classes.contentArea}>{<ClientGrid client={selectedClient} projectSearchQuery={searchQuery} />}</div>
                </>
            </ErrorPlaceholder>
        </div>
    );
};

export default LandingView;
