import { fetchAccessibleClientsAndProjects, fetchCurrentUser, fetchModules } from "actions/core";
import LoadingPlaceholder from "components/LoadingPlaceholder/LoadingPlaceholder";
import { useRequestInterceptor } from "hooks/useRequestInterceptor";
import { FCWC, useEffect, useState } from "react";
import { useAppDispatch } from "store/hooks/useAppDispatch";

const Bootstrap: FCWC = ({ children }) => {
    const [criticalResourcesLoaded, setCriticalResourcesLoaded] = useState(false);
    const dispatch = useAppDispatch();

    const interceptorLoading = useRequestInterceptor();

    useEffect(() => {
        // Non-Critical
        dispatch(fetchModules());
        dispatch(fetchAccessibleClientsAndProjects());

        // Critical
        const userRolesPromise = dispatch(fetchCurrentUser());
        const criticalPromises = [userRolesPromise];
        Promise.all(criticalPromises).then(() => setCriticalResourcesLoaded(true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <LoadingPlaceholder loading={!criticalResourcesLoaded || interceptorLoading} message="Getting user data" textVariant="body1" spinnerSize={50}>
            {children}
        </LoadingPlaceholder>
    );
};

export default Bootstrap;
