import { toastr as reduxToastr } from "react-redux-toastr";

const defaultOptions = (action: string) => {
    return {
        position: "bottom-right",
        component: <div hidden data-testid={`qa-notification-${action}`}></div>
    };
};
const CustomToastr = {
    info: (text: string, opt: any = defaultOptions("info")) => reduxToastr.info("", text, opt),
    success: (text: string, opt: any = defaultOptions("success")) => reduxToastr.success("", text, opt),
    warning: (text: string, opt: any = defaultOptions("warning")) => reduxToastr.warning("", text, opt),
    error: (text: string, opt: any = defaultOptions("error")) => reduxToastr.error("", text, opt),
    confirm: (text: string, opt: any) => reduxToastr.confirm(text, opt)
};

//this helps us with auto-import
const toastr = CustomToastr;

export default toastr;
