import { MenuItem, Tooltip } from "@mui/material";
import { FC } from "react";
import packageJson from "../../../../package.json";
import { useStyles } from "./styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import toastr from "components/CustomToastr/CustomToastr";
import { MENU_ITEM_HEIGHT } from "../UserMenu";
import CustomTypography from "components/CustomTypography/CustomTypography";

const Version: FC = () => {
    const classes = useStyles();

    const onClickCopy = () => {
        navigator.clipboard.writeText(packageJson.version);
        toastr.info("Version number copied to clipboard");
    };

    const versions = packageJson.version.split(".");

    return (
        <MenuItem onClick={onClickCopy} divider sx={{ height: MENU_ITEM_HEIGHT }}>
            <Tooltip title="Copy version number to clipboard">
                <div className={classes.version}>
                    <span>
                        <CustomTypography variant="caption" textWeight="bold" sx={{ marginRight: 0.25 }} color="inherit">
                            v{versions[0]}.{versions[1]}
                        </CustomTypography>
                        <CustomTypography variant="caption" color="inherit">
                            ({versions[2]})
                        </CustomTypography>
                    </span>
                    <ContentCopyIcon fontSize="small" />
                </div>
            </Tooltip>
        </MenuItem>
    );
};

export default Version;
