import { makeStyles } from "@mui/styles";
import { lighten } from "@mui/material/styles";

const generalFontSize = 13;

export const useStyles = makeStyles((theme: any) => {
    return {
        rootItem: {
            width: 400,
            minHeight: 48,
            padding: 0,

            "&:not(:first-child)": {
                borderTop: `1px solid ${theme.customColors.borderColor}`
            }
        },
        activeText: {
            color: theme.customColors.successColor,
            fontSize: generalFontSize
        },
        moduleIcon: {
            width: 25,
            marginRight: 10
        },
        moduleTitle: {
            fontWeight: 600,
            fontSize: generalFontSize
        },
        moduleDescription: {
            color: theme.customColors.appTextColorLight,
            fontSize: generalFontSize,
            lineHeight: 1.2
        },
        checkedIcon: {
            color: theme.customColors.successColor,
            fontSize: 15,
            marginRight: 3
        },
        noAccessBtn: {
            color: theme.palette.primary.main,
            backgroundColor: lighten(theme.palette.primary.main, 0.92),
            padding: "5px 10px 5px 10px",
            fontSize: generalFontSize
        },
        clickableContainer: {
            paddingInline: theme.spacing(2)
        }
    };
});

