import { Button } from "@mui/material";
import CustomTypography from "../CustomTypography/CustomTypography";
import { FCWC } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useStyles } from "./styles";

type Props = {
    error: boolean;
    message: string;
    textVariant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button" | "overline" | "inherit" | undefined;
    onTryAgain: () => void;
};

const ErrorPlaceholder: FCWC<Props> = ({ children, error, message, textVariant, onTryAgain }) => {
    const classes = useStyles();
    return error ? (
        <div className={classes.container}>
            <CustomTypography className={classes.bottomMargin} variant={textVariant}>
                {message}
            </CustomTypography>
            {onTryAgain && (
                <Button onClick={onTryAgain} size="large" startIcon={<RefreshIcon />} color="primary" variant="contained">
                    Try Again
                </Button>
            )}
        </div>
    ) : (
        <>{children}</>
    );
};

ErrorPlaceholder.defaultProps = {
    message: "Encountered an error",
    textVariant: "body1"
};

export default ErrorPlaceholder;
