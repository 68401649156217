import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { FCWC } from "react";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import theme from "muiTheme";

const muiCache = createCache({
    key: "mui",
    prepend: true
});

const StylingProviders: FCWC = ({ children }) => {
    return (
        <CacheProvider value={muiCache}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>{children}</ThemeProvider>
            </StyledEngineProvider>
        </CacheProvider>
    );
};

export default StylingProviders;
