import "./App.scss";
import Bootstrap from "Bootstrap";
import LandingView from "components/LandingView/LandingView";
import { Redirect, Route, Switch } from "react-router-dom";
import ReduxToastr from "react-redux-toastr";
import SignOutView from "components/SignOutView/SignOutView";

const App = () => (
    <div className="app">
        <Switch>
            <Route exact path="/sign-out">
                <SignOutView />
            </Route>
            <Route exact path="/">
                <Bootstrap>
                    <LandingView />
                </Bootstrap>
            </Route>
            <Redirect to="/" />
        </Switch>

        <ReduxToastr timeOut={4000} newestOnTop={true} position="top-right" transitionIn="fadeIn" transitionOut="fadeOut" closeOnToastrClick progressBar />
    </div>
);

export default App;
