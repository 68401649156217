import {User} from "models/User";
import {RootState} from "../store/models/RootState";
import {createParameterizedSelector, forwardArg} from "./common";
import {CoreRole} from "../models/core/CoreRole";

export const getUser = (state: RootState) => state.auth.user;

export const getAccountForClient = createParameterizedSelector([getUser, forwardArg], (user: User, clientId: string) => {
    const isSystemAdmin = user?.accounts[0].accountType === CoreRole.SystemAdmin;
    if (isSystemAdmin) return user?.accounts[0];
    
    return user?.accounts.find((x) => x.client.id === clientId);
});
